import {Component, OnInit} from '@angular/core';
import {ComponentBaseDirective} from '@project-lib/core/component-base';
import {TranslationService} from '@project-lib/core/localization';
import {IconPacksManagerService} from '@project-lib/theme/services';
import {SpinnerService} from './services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ComponentBaseDirective implements OnInit {
  title = 'sdlc-ai-ui';
  loading = false;

  constructor(
    private readonly iconMgr: IconPacksManagerService,
    private readonly languageTranslateService: TranslationService,
    private spinnerService: SpinnerService,
  ) {
    super();
    this.spinnerService.spinnerState$.subscribe(state => {
      this.loading = state;
    });
  }

  ngOnInit(): void {
    this.languageTranslateService.init();
    this.iconMgr.registerFontAwesome();
    this.iconMgr.registerSvgs();
  }
}
